import React from 'react';

const Summary = ({ data }) => (
  <section className="py-5 border-b border-neutral-300 md:flex items-center">
    <img
      className="rounded-full mx-auto w-2/3 md:w-1/5 mb-5 md:mb-0 dont-print"
      src="./profile.jpg"
      alt="profile"
    />
    <p
      className="text-center md:tracking-wide leading-relaxed md:text-left md:mx-8 md:text-md lg:text-lg"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  </section>
);

export default Summary;
