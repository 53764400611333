import React from 'react';

const Projects = ({ data }) => (
  <section>
    <h1 className="section-header">Side projects</h1>
    {data.map(item => (
      <article className="my-5" key={item.name}>
        <h2 className="item-header">{item.name}</h2>
        <h3 className="item-sub">{item.company}</h3>
        <p
          className="py-2"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
        <div className="flex justify-end">
          {item.links.map(link => (
            <a
              className="btn btn-secondary ml-2 dont-print"
              href={link.www}
              target="_blank"
              rel="noopener noreferrer"
              key={link.text}
            >
              {link.text}
            </a>
          ))}
        </div>
        <div className="flex flex-col">
          {item.links.map(link => (
            <p className="item-small print-only" key={link.text}>
              {link.text}: {link.www}
            </p>
          ))}
        </div>
      </article>
    ))}
  </section>
);

export default Projects;
